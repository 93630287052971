import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import styled from '@emotion/styled'

import Container from 'components/Container'
import Modal from 'components/Modal/Modal'

import useWindowSize from 'hooks/useWindowSize';
import vw, { vwMobile } from 'styles/vw';
import media from 'styles/media';

import portfolios from 'data/portfolio';

function ProjectDetail() {
  const params = useParams();
  const windowSize = useWindowSize();
  const [project, setProject] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(windowSize.width < 576);
  const [showMoreMobile, setShowMoreMobile] = useState(false);


  useEffect(() => {
    setProject(portfolios.find(element => element.id === params.id))
  }, [params.id])

  useEffect(() => {
    if (windowSize.width >= 576) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [windowSize, isMobile])


  const handleClickImage = (index) => {
    setModalOpen(true);
    setSlideIndex(index)
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  }

  const handleClickShowMore = () => {
    setShowMoreMobile(!showMoreMobile);
  }

  return (
    <Root>
      <Nav>
        <Link to="/home">Home</Link>
        <span> / </span>
        <Link to="/portfolio">Portfolio</Link>
        <span> / </span>
        <Link to={window.location.pathname}>{project?.name}</Link>
      </Nav>
      {
        <Project>
          <InfoContainer>
            <Name>{project?.name}</Name>
            <Info>
              <span>{`${project?.city}/${project?.space}/${project?.size}`}</span>
            </Info>
            <Description>
              <DescriptionLeft>
                {project?.description?.left}
              </DescriptionLeft>
              <DescriptionRight>
                <DescriptionRightContent showAll={!isMobile || showMoreMobile}>
                  {project?.description?.right}
                </DescriptionRightContent>
                {
                  isMobile && <ShowMore showMore={showMoreMobile} onClick={handleClickShowMore}>Show {!showMoreMobile ? 'More' : 'Less'}</ShowMore>
                }

              </DescriptionRight>
            </Description>
            <Info>
              <>
                <span>{project?.photographic && '攝影'}</span>
                <span>{project?.photographic}</span>
              </>
              <>
                <span>{project?.furniture && '傢俱'}</span>
                <span>{project?.furniture}</span>
              </>
              <>
                <span>{project?.coop && '合作'}</span>
                <span>{project?.coop}</span>
              </>
            </Info>
          </InfoContainer>
          <Gallery>
            {
              project?.gallery.map((image, index) => (
                <ImageWrapper key={`project detail ${image.url}`} imageURL={image} onClick={() => handleClickImage(index)} />
              ))
            }
          </Gallery>
        </Project>
      }
      <Modal show={modalOpen} data={project?.gallery} slideIndex={slideIndex} onCloseModal={handleCloseModal} />
    </Root>
  )
}

const Root = styled(Container)``;

const Nav = styled.nav`
  color: #141414;

  ${vw('font-size', 12)}
  ${vw('margin-top', 20)}
  ${vw('margin-bottom', 15)}
  ${vw('padding-left', 0, 20, 85)}
  ${vw('padding-right', 0, 20, 85)}

  > a {
    color: #141414;
    text-decoration: none;

    ${vw('font-size', 12)}

    &:hover {
      color: #9e3882;
    }
  }
`;

const Project = styled.div`
  ${vw('padding-bottom', 30)}
`;

const InfoContainer = styled.div`
  ${vw('padding-left', 0, 20, 85)}
  ${vw('padding-right', 0, 20, 85)}
`;

const Name = styled.h1`
  ${vw('font-size', 12, 14, 13)}
  ${vw('margin-bottom', 6, 12, 5)}
`;

const Info = styled.div`
  border-bottom: 1px solid gray;
  display: grid;
  grid-template-columns: max-content max-content;

  ${vw('font-size', 12)}
  ${vw('padding-bottom', 5, 12, 12)}
  ${vw('grid-column-gap', 16)}
  ${vw('grid-row-gap', 2)}

  & span {
    color: grey;
  }

  & span:nth-last-of-type(2) {
    color: grey;
  }

  & span:last-of-type {
    color: grey;
  }
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  color: grey;
  font-weight: normal;

  @media ${media.tablet} {
    flex-direction: row;
  }

  ${vw('font-size', 12)}
  ${vw('column-gap', 0, 40, 50)}
  ${vw('line-height', 25)}
  ${vw('margin-bottom', 30)}
  ${vw('padding-top', 15)}
`;

const DescriptionLeft = styled.div`
  width: 100%;
  text-align: justify;

  @media ${media.tablet} {
    width: 50%;
  }

  > p {
    margin: 0;
    padding: 0;
  }
`;

const DescriptionRight = styled.div`
  width: 100%;
  text-align: justify;

  @media ${media.tablet} {
    width: 50%;
  }
`;

const DescriptionRightContent = styled.p`
  width: 100%;
  max-height: ${props => props.showAll ? '100%' : `${vwMobile(25)}`};
  margin: 0;
  padding: 0;
  white-space: ${props => !props.showAll && 'nowrap'};
  overflow: ${props => !props.showAll && 'hidden'};
  text-overflow: ellipsis;
`;

const ShowMore = styled.button`
  display: inline-block;
  border: none;
  background: none;
  color: #9e3882;
  padding: 0;

  ${vw('font-size', 12)}
  ${vw('line-height', 25)}
`;

const Gallery = styled.div`
  display: grid;
  grid-template-columns: auto;

  ${vw('grid-column-gap', 0, 40, 50)}
  ${vw('grid-row-gap', 40, 80)}
  ${vw('margin-top', 45, 80)}
  ${vw('padding-left', 0, 20, 85)}
  ${vw('padding-right', 0, 20, 85)}
  ${vw('padding-bottom', 45, 80)}
  

  @media ${media.tablet} {
    grid-template-columns: auto auto;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  background-image: ${props => `url(${props.imageURL})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;

  ${vw('height', 200, 240, 400)}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export default ProjectDetail
