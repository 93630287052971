import appConfig from 'config/app.conf';

const { stage } = appConfig;

function vw(property, wMobile, wTablet, wDesktop) {
  if (typeof wTablet === 'undefined') {
    wTablet = wMobile;
  }

  if (typeof wDesktop === 'undefined') {
    wDesktop = wTablet;
  }

  return `${property}: ${
    typeof wMobile === 'string' ? wMobile : vwMobile(wMobile)
  };
    @media (min-width: ${appConfig.mediaQuery.tablet}px) {
      ${property}: ${typeof wTablet === 'string' ? wTablet : vwTablet(wTablet)};
    }
    @media (min-width: ${appConfig.mediaQuery.desktop}px) {
      ${property}: ${
    typeof wDesktop === 'string' ? wDesktop : vwDesktop(wDesktop)
  };
    }
  `;
};

export const vwMobile = (width) => {
  return `${(width / stage.mobile) * 100}vw`;
};

export const vwTablet = (width) => {
  return `${(width / stage.tablet) * 100}vw`;
};

export const vwDesktop = (width) => {
  return `${(width / stage.desktop) * 100}vw`;
};

export default vw;
