import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import styled from '@emotion/styled';

import Header from 'components/Header';
import Footer from 'components/Footer';

import routes from 'routes';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function App() {
  return (
    <Root>
      <BrowserRouter>
        <Header />
        <Routes>
          {
            routes.map((route) => (
              <Route path={route.path} element={route.element} key={route.path}/>
            ))
          }
        </Routes>
        <Footer />
      </BrowserRouter>
    </Root>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  @font-face {
    font-family: 'Roboto Condensed';
    src: url('public/assets/RobotoCondensed-Regular.woff2') format('woff2'),
        url('public/assets/RobotoCondensed-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  h2, h3 {
    margin: 0;
    padding: 0;
  }
`;

export default App;
