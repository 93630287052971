const shixu = {
  id: 'shixu',
  name: '拾序',
  city: '臺中',
  space: '住宅',
  size: '22坪',
  description: {
    left: (
      <>
        臺中大里區，往返臺北與臺中設計與監工的幸福旅程
        設計師甫踏入屋內丈量，立刻發現空間中有過多櫃體及門板反光（因部分櫃門是結晶版及玻璃門板），繁複的壁板、花紋多樣的壁紙包覆牆面、燈光色調白黃混用不均，這些前屋主留下的裝修都讓空間看起來更加擁擠，視覺也較為凌亂。但開心的是，房子本身位於較高樓層，窗外光線、徐徐微風都很適合結合設計引入室內，「與自然和諧共生」以此為出發點，營造舒適的生活空間。
        這次屋主給的挑戰條件是在「隔間不動、保留大部分櫃體」的前提之下，讓空間變得更加協調。於是，改造的首要重點是「如何去蕪存菁，重拾住宅原始的簡淨樣貌」。
        我們與屋主討論後，取得一個基本共識，「將既有機能性櫃體作局部調整，其餘空間以活動傢俱方式擴充」。首先，公共區域（客廳＆餐廳）順應原有木紋的語彙，將原先反射強烈的門板，換成與櫃體同色系的門片，降低空間反光的程度，讓整體顯得更乾淨、清爽；
      </>
    ),
    right: (
      <>
        書房的窗邊臥榻，作為日常繁忙生活的休憩區，選擇與木地板較為相近的顏色，減輕臥榻的個體感，創造空間的視覺延伸性。
        臥室部分，榻榻米是空間中的主要特色，原生紋理自然草感，搭配充沛的日光，營造出悠閒舒適的氛圍。當時跟業主在手工疊蓆店挑選布邊款式時，捨棄較為常見的綠色布邊，選用「無布邊」的榻榻米，讓空間色調更簡單，也更凸顯榻榻米本身的暖米色系，勾勒出沉靜質樸的感受，呈現舒適宅的小清新。
        此外，特別一提的是，我們試坐無數款的餐椅，爲屋主挑選了能幫助減輕脊柱壓力的椅子。椅背設計是挑選的重點，例如高度較高的靠背、身體服貼性、椅背弧度等都是要注意的地方， 讓屋主坐在餐椅享受美食時，腰椎獲得較好的支撐，減輕脊椎負擔。
        捨棄「不需要」，留下精選的美好，每一隅空間都可以隨時隨地享受，擁抱愜意慢活的舒適宅。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/shixu/1.jpg'),
    require('assets/images/portfolio/shixu/2.jpg'),
    require('assets/images/portfolio/shixu/3.jpg'),
    require('assets/images/portfolio/shixu/4.jpg'),
    require('assets/images/portfolio/shixu/5.jpg'),
    require('assets/images/portfolio/shixu/6.jpg'),
    require('assets/images/portfolio/shixu/7.jpg'),
    require('assets/images/portfolio/shixu/8.jpg'),
    require('assets/images/portfolio/shixu/9.jpg'),
    require('assets/images/portfolio/shixu/10.jpg'),
    require('assets/images/portfolio/shixu/11.jpg'),
    require('assets/images/portfolio/shixu/12.jpg'),
    require('assets/images/portfolio/shixu/13.jpg'),
    require('assets/images/portfolio/shixu/14.jpg'),
    require('assets/images/portfolio/shixu/15.jpg'),
    require('assets/images/portfolio/shixu/16.jpg'),
    require('assets/images/portfolio/shixu/17.jpg'),
    require('assets/images/portfolio/shixu/18.jpg'),
    require('assets/images/portfolio/shixu/19.jpg'),
    require('assets/images/portfolio/shixu/20.jpg'),
  ]
};

export default shixu;