import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import vw from 'styles/vw';

function Container(props) {
  const { children } = props;

  return (
    <Root {...props}>
      {children}
    </Root>
  )
}

Container.propTypes = {
  children: PropTypes.node
}

const Root = styled.div`
  ${vw('padding-left', 20, 20, 30)}
  ${vw('padding-right', 20, 20, 30)}
`;

export default Container
