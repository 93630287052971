const chitaoflower = {
  id: "chitaoflower",
  name: "赤陶之花",
  city: "新北",
  space: "住宅",
  size: "14坪",
  description: {
    left: (
      <>
        空間原先有道分割廚房與客廳的大牆，設計時配合屋主的生活軌跡，輕巧地消失於空間，讓視覺整體更為寬敞開闊。<br />
        從大門開始，兩側頂天的櫃體可以收納生活物品，特別的是，櫃體內隱藏的小小的密室通道，等待未來加入的喵星人夥伴探索迷蹤。
        客廳化繁為簡的以「單椅及窗邊臥榻」為主軸，廚房則跳脫傳統框架，將炊煮移至中島，靠牆洗滌以金屬層板取代吊櫃，創造出開闊視野與迴游動線。
        而公私領域的界線以「赤陶色」牆體界定了生活與休息場域，透過拉門開闔保持空間通透彈性，層層串連光線與生活軌跡。
      </>
    ),
    right: (
      <>
        
        整體色系注入「赤陶暖色系」舖畫空間，牆體與中島使用礦物塗料，層層漆出脫俗不凡的溫潤質感色調；此外，利用櫃體型態與弧形天花板的切割感，並適度加入溫潤木頭與灰白石材，形塑乾淨的放大空間效果，成為生活雋永的底蘊。<br />
        屋主時常環遊世界，每趟旅程帶回的物品皆會安放棲身空間各處，一物一景，如同在赤陶間盛開出各式繁花，藴育著空間的溫暖與幸福。
      </>
    ),
  },
  gallery: [
    require("assets/images/portfolio/chitaoflower/01.jpg"),
    require("assets/images/portfolio/chitaoflower/02.jpg"),
    require("assets/images/portfolio/chitaoflower/03.jpg"),
    require("assets/images/portfolio/chitaoflower/04.jpg"),
    require("assets/images/portfolio/chitaoflower/05.jpg"),
    require("assets/images/portfolio/chitaoflower/06.jpg"),
    require("assets/images/portfolio/chitaoflower/07.jpg"),
    require("assets/images/portfolio/chitaoflower/08.jpg"),
    require("assets/images/portfolio/chitaoflower/09.jpg"),
    require("assets/images/portfolio/chitaoflower/10.jpg"),
    require("assets/images/portfolio/chitaoflower/11.jpg"),
    require("assets/images/portfolio/chitaoflower/12.jpg"),
    require("assets/images/portfolio/chitaoflower/13.jpg"),
    require("assets/images/portfolio/chitaoflower/14.jpg"),
    require("assets/images/portfolio/chitaoflower/15.jpg"),
    require("assets/images/portfolio/chitaoflower/16.jpg"),
    require("assets/images/portfolio/chitaoflower/17.jpg"),
    require("assets/images/portfolio/chitaoflower/18.jpg"),
    require("assets/images/portfolio/chitaoflower/19.jpg"),
    require("assets/images/portfolio/chitaoflower/20.jpg"),
  ],
};

export default chitaoflower;
