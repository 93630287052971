const smallResidence = {
  id: 'small-residence',
  name: '小居',
  city: '新北',
  space: '住宅',
  size: '12坪',
  description: {
    left: (
      <>
        業主是一對喜愛鄉村風的小夫妻，享受鄉村風特有的復古樸質感的溫暖感。
        原有室內已經包含一些復古的元素，有早期流行的天花石膏線板，具線板門的臥室等素材，於是我們決定將這些元素保留下來，跟空間融合，使現代精緻與鄉村樸實感同時並存。
        此案小坪數空間在格局規劃上的改裝重點之一就是「廚房」，因為男主人非常喜歡下廚(滋味一級讚)，希望除了實用性的充足備料空間外，也想在視覺上保有鄉村風的元素，因此在廚房定調花了一些時間來達成共識。當我們把花磚及廚具門板材料搬到現場時，小夫妻業主對同一塊門板露出閃亮亮雙眼，於是，我們就以此門板為整體空間的重點色，延伸串起空間的主要區域。
        延伸空間感是這次最大的挑戰，因為小居的坪數(約12坪)相當有限，所以在傢俱搭配上變得格外重要。
      </>
    ),
    right: (
      <>
        原先屋內使用的傢俱略為擁擠，將空間分割成數塊，我們決定在空間中央選用靈活移動的中島櫃及方形餐桌，個別使用時，餐桌可以化身多功能桌子(化妝桌、書桌等)，中島櫃為廚房料理台，合併使用時，則形成長形中島，招待登門拜訪的好友，因此業主可依照居家使用或朋友來訪時，自行調整使用方式，兼具功能性及機能性。
        接著，靠牆式的傢俱方面，特別選用外觀略有裝飾型態，但又不過於浮誇(例如MIRANDA餐櫃、布質HILL2 BE沙發、鄉村風桌腳的書桌)，在空間內布置中，讓現代風格混入傳統鄉村感，平衡並兼具兩者的特色。
        最後小居突然來個小驚喜，改造過程中，業主臨時決定汰換原先廁所的塑膠門，於是我們採用山形紋夾板的材質，請木工師傅以油漆製作成夾板染色門，為原本的空間再度點綴出活潑又溫潤的視覺舒適感。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/small-residence/1.jpg'),
    require('assets/images/portfolio/small-residence/2.jpg'),
    require('assets/images/portfolio/small-residence/3.jpg'),
    require('assets/images/portfolio/small-residence/4.jpg'),
    require('assets/images/portfolio/small-residence/5.jpg'),
    require('assets/images/portfolio/small-residence/6.jpg')
  ]
};

export default smallResidence;
