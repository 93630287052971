import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import Container from 'components/Container';

import vw from 'styles/vw';
import media from 'styles/media';

import portfolios from 'data/portfolio';

function Portfolio() {
  return (
    <Root as="main">
      {
        portfolios.length>1 && portfolios.map((project) => (
          <Project key={`project ${project.id}`}>
            <ProjectImageWrapper>
              <ProjectImage imageURL={project.gallery[0]}>
                <StyledLink to={`/portfolio/${project.id}`}>View More</StyledLink>
              </ProjectImage>
            </ProjectImageWrapper>
            <ProjectName to={`/portfolio/${project.id}`}>{project.name}</ProjectName>
          </Project>
        ))
      }
    </Root>
  )
}

Portfolio.propTypes = {}

const Root = styled(Container)`
  display: grid;
  grid-template-columns: auto;

  ${vw('grid-column-gap', 10)}
  ${vw('grid-row-gap', 60, 70, 100)}
  ${vw('margin-top', 10, 40, 80)}
  ${vw('padding-bottom', 70, 80, 100)}

  @media ${media.desktop} {
    grid-template-columns: auto auto;
  }
`;

const Project = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  > p {
    color: #141414;
    
    ${vw('font-size', 12, 15)}
  }

  &:hover {
    > div > div { // Project
      &:before {
        opacity: 1;
      }
      > a {  // view more button
        opacity: 1;
      }
    }
    > a {
      color: #9e3882;
    }
  }
`;

const ProjectImageWrapper = styled.div`
  width: 100%;

  ${vw('height', 200, 340, 350)}
`;

const ProjectImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: ${props => `url(${props.imageURL})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: all 0.25s ease-in-out;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  outline: none;
  border: 1px solid white;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  opacity: 0;
  transition: all 0.25s ease-in-out;
  z-index: 2;

  ${vw('font-size', 12)}
  ${vw('padding-top', 6)}
  ${vw('padding-bottom', 6)}
  ${vw('padding-left', 12)}
  ${vw('padding-right', 12)}
`;

const ProjectName = styled(Link)`
  text-decoration: none;
  outline: none;
  color: #141414;
    
  ${vw('font-size', 12, 15)}
  ${vw('padding-top', 14)}
  ${vw('padding-bottom', 6)}
  ${vw('padding-left', 12)}
  ${vw('padding-right', 12)}
`;

export default Portfolio
