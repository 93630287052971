const home = [
  {
    src: require('assets/images/home/1.jpg'),
    position: 'center',
  },
  {
    src: require('assets/images/home/2.jpg'),
    position: 'bottom',
  },
  {
    src: require('assets/images/home/3.jpg'),
    position: 'bottom',
  },
  {
    src: require('assets/images/home/4.jpg'),
    position: 'center',
  },
  {
    src: require('assets/images/home/5.jpg'),
    position: 'center',
  }
];

export default home;
