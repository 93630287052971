import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import Slider from "react-slick";
import NextArrow from './elements/NextArrow';
import PrevArrow from './elements/PrevArrow';

import vw from 'styles/vw';

function Modal(props) {
  const { show, data, slideIndex, onCloseModal } = props;
  const $sliderRef = useRef(null);
  const [gallery, setGallery] = useState([]);

  const settings = {
    dots: false,
    infinite: true,
    centerMode: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  useEffect(() => {
    setGallery(data);
  }, [show, data])
  

  useEffect(() => {
    $sliderRef.current.slickGoTo(slideIndex);
  }, [slideIndex])
  

  return (
    <Root show={show}>
      <Slider {...settings} ref={$sliderRef}>
        {
          gallery?.length>0 && gallery.map((image) => (
            <Slide key={image}>
              <img src={image} alt={image} />
            </Slide>
          ))
        }
      </Slider>
      <FontAwesomeIcon className='fa-solid fa-xmark' onClick={onCloseModal} />
    </Root>
  )
}

Modal.propTypes = {
  show: PropTypes.bool,
  data: PropTypes.array,
  slideIndex: PropTypes.number, 
  onCloseModal: () => {}
}

const Root = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${props => props.show ? '1' : '0'};
  pointer-events: ${props => props.show ? 'auto' : 'none'};
  transition: all 0.25s ease-in-out;
  z-index: 10;
`;

const Slide = styled.div`
  width: 100%;
  height: 100vh;
  display: flex !important;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  
  > img {
    width: auto;
    height: auto;
    max-width: 80%;
    max-height: 80vh;
    border: none;
  }
`;

const FontAwesomeIcon = styled.i`
  position: absolute;
  color: white;
  transition: all 0.25s ease-in-out;
  z-index: 1;
  cursor: pointer;

  ${vw('font-size', 30, 30, 40)}
  ${vw('top', 20)}
  ${vw('right', 20)}

  &:hover {
    color: #9e3882;
  }
`;

export default Modal
