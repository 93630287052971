const sunset = {
  id: 'sunset',
  name: 'Sunset｜服飾店',
  city: '臺北',
  space: '商空',
  size: '15坪',
  photographic: 'Terry Chiang',
  apparel: 'Sunset',
  description: {
    left: (
      <>
        Sunset，韓系衣服及飾品為主的店鋪，躋身五光十色的繁華東區，承載著女孩們的夢想，閃耀著光芒。
        此案設計師考量的最大重點，是讓衣服及飾品成為空間內展示的主角，因此，我們決定以「裸色元素」及「明亮光線」為設計定調，裸色可以完美融合所有色系的衣物，視覺上亦達到放大空間的效果。
      </>
    ),
    right: (
      <>
        店鋪的門面及天花板，以「ㄇ字框型」圍繞入口，一踏入店裡就彷彿進入專屬的置衣間。接著，特別挑選具有高、低層次的壁掛架、活動衣架及飾品陳列櫃，達到些微錯落的層次感，更妥善地利用整體空間。
        結帳臺部分，選用「立體磁磚」作為櫃臺的本體，讓櫃臺本身不過於呆版，並以實用的耐磨、耐刮的「仿實木美耐板」作為檯面，二者交織出低調大器的視覺感受。另外，透過軌道燈，讓業主可以配合衣物所擺設的位置，彈性調整不同的燈光角度，並於空間中心擺放球型的玻璃吊燈，讓明黃調的光暈為室內增添溫潤的舒適氣氛。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/sunset/1.png'),
    require('assets/images/portfolio/sunset/2.png'),
    require('assets/images/portfolio/sunset/3.jpg'),
    require('assets/images/portfolio/sunset/4.jpg'),
    require('assets/images/portfolio/sunset/5.jpg'),
    require('assets/images/portfolio/sunset/6.jpg'),
    require('assets/images/portfolio/sunset/7.jpg'),
    require('assets/images/portfolio/sunset/8.jpg'),
    require('assets/images/portfolio/sunset/9.jpg'),
    require('assets/images/portfolio/sunset/10.jpg'),
    require('assets/images/portfolio/sunset/11.jpg')
  ]
};

export default sunset;
