const pandaTeaCFPoloPark = {
  id: "panda-tea-cf-polo-park",
  name: "PANDA TEA - CF POLO PARK",
  city: "加拿大",
  space: "商空",
  size: "15坪",
  description: {
    left: (
      <>
        「佇立於繁華商場（CF POLO
        PARK）的一抹綠洲」，述說的正是這間飲茶店。室內以「綠洲」作為設計概念，並融合PANDA
        TEA品牌「新鮮鮮萃」理念為顏色基調，平衡都市商場的緊湊步調，讓整體空間呈現綠意盎然的自然悠閒感。
        <br />
        門面設計選用淡綠特殊紋理的立體磚及銀色髮絲紋不鏽鋼為主要基底，輔以燈光佈局創造視覺焦點，突顯品牌的明亮獨特風格，更豐富空間的層次感，讓顧客在踏入空間的第一步，感受安定清新的自然系氛圍。
        <br />
        空間中，牆面以「圓弧」劃分動線，如同綠洲中流水般的流動線條，跳脫常見的方框界線，讓場域注入靈活的行走動線，開闊空間尺度，打造寬敞彈性變化的走道；展示牆的櫥窗空間，讓茶飲商品在此恣意綻放，透過美學與品茶的相互交織，創造視覺與味覺的饗宴。
      </>
    ),
    right: (
      <>
        串聯外部商場與內部店面，特定訂製「中島沙發」為空間加入一絲巧思，讓場域的出入富含流動性，吹拂著悠哉放鬆的飲茶體驗；圓潤飽滿的純白色吊燈，汲取綠洲清晨露珠的姿態，感受茶葉於露珠中舒展，譜出溫潤而純淨的氛圍。另外，吧檯區則以「弧形」板材作為裝飾，呼應牆面的圓弧設計，以木皮板的樸質質地，散發溫暖舒適的氛圍。
        <br />
        本案運用莫蘭迪綠為空間基底色調，象徵都市內一畝綠州的氣息，並輔以純白色調鋪陳空間質感，平衡空間整體的視覺溫度，點亮舒適的美好氛圍。空氣飄逸著淡淡的溫暖奶茶香，撫平生活匆促的腳步，飲用一杯回甘茶品，還原淡雅地日常生活，透過空間色調及圓弧形的流動設計，讓顧客的心回歸平靜舒適的樣貌。
      </>
    ),
  },
  gallery: [
    require("assets/images/portfolio/panda-tea-cf-polo-park/01.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/02.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/03.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/04.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/05.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/06.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/07.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/08.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/09.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/10.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/11.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/12.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/13.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/14.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/15.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/16.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/17.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/18.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/19.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/20.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/21.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/22.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/23.jpg"),
    require("assets/images/portfolio/panda-tea-cf-polo-park/24.jpg"),
  ],
};

export default pandaTeaCFPoloPark;
