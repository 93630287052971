import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import NavDesktop from './Nav/NavDesktop'
import NavMobile from './Nav/NavMobile'

import Container from './Container'

import vw from 'styles/vw';

function Header() {
  const [openHamburger, setOpenHamburger] = useState(false);

  const handelMenuClicked = () => {
    setOpenHamburger(!openHamburger);
  }

  const handleClosedMenu = () => {
    setOpenHamburger(false);
  }

  return (
    <Root as="header">
      <Link to="/">
        <Logo src={require('assets/images/icons/logo.png')} alt="logo" />
      </Link>
      <NavDesktop />
      <NavMobile openHamburger={openHamburger} handelMenuClicked={handelMenuClicked} handleClosedMenu={handleClosedMenu} />
    </Root>
  )
}

const Root = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;

  ${vw('padding-top', 20)}
  ${vw('padding-bottom', 20)}
`;

const Logo = styled.img`
  width: auto;
  border-radius: 5px;

  ${vw('height', 50, 50, 80)}
`;

export default Header
