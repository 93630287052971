import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from '@emotion/styled'

import vw from 'styles/vw';
import media from 'styles/media';

function NavDesktop() {
	return (
		<Root>
			<NavLink activeclassname="selected" to="/about">About</NavLink>
			<NavLink activeclassname="selected" to="/portfolio">Portfolio</NavLink>
      <NavLink activeclassname="selected" to="/service-process">Service Process</NavLink>
			<NavLink activeclassname="selected" to="/contact">Contact</NavLink>
		</Root>
	)
}

NavDesktop.propTypes = {}

const Root = styled.nav`
	display: none;
  align-items: center;

  ${vw('gap', 10, 26, 36)}

	@media ${media.tablet} {
    display: flex;
  }

  > a {
    /* width: max-content; */
    text-align: center;
    transition: all 0.25s ease-in-out;
    color: #141414;
    text-decoration: none;
    outline: none;
    border: none;
    width: min-content;
    white-space: nowrap;
    /* ${vw('width', 50, 70, 125)} */
    ${vw('font-size', 12, 14, 17)}
    
    &:hover {
      color: #9e3882;
    }

    &.active {
      color: #9e3882;
      font-weight: bold;
    }
  }
`;

export default NavDesktop
