import interiorDesignIcon from 'assets/images/icons/interior-design.svg';

const designStage = {
  title: '設計階段',
  icon: interiorDesignIcon,
  stages: [
    {
      title: '初步接洽 / 設計提案',
      listItems: [
        '-瞭解使用者的需求喜好及屋況條件',
        '-現場丈量及紀錄',
        '-平面設計規劃圖（初版）',
        '-提案討論設計方向（風格簡報）'
      ]
    },
    {
      title: '設計合約簽訂',
      listItems: [
        '-平面設計規劃圖（正式版）',
        '-室內設計3D圖',
        '-平立面圖及細部施工圖',
        '-確認空間材料',
        '-完整分項工程報價'
      ]
    }
  ]
};

export default designStage;