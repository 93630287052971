import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import vw from 'styles/vw';

function NextArrow(props) {
	const { onClick } = props;

  return (
    <Root className='fa-solid fa-angle-right' onClick={onClick} />
  )
}

NextArrow.propTypes = {
	onClick: PropTypes.func
}

const Root = styled.i`
	color: white;
	position: absolute;
	top: 50%;
	transform: translate3d(0, -50%, 0);
	z-index: 1;
	transition: all 0.25s ease-in-out;
	cursor: pointer;

	${vw('font-size', 30, 30, 40)};
	${vw('right', 30, 30, 40)}

	&:hover {
    color: #9e3882;
  }
`;

export default NextArrow
