import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'

import Container from 'components/Container';

import vw from 'styles/vw';
import media from 'styles/media';

function Banner() {
  return (
    <Root>
      <h2>Zhu Shi</h2>
      <RightCol>
        <p>透過溝通，形塑符合使用者空間的美學生活，<br />體現單純的美好。</p>
        <Link to="/portfolio">Portfolio</Link>
      </RightCol>
    </Root>
  )
}

const Root = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${media.tablet} {
    flex-direction: row;
  }

  ${vw('margin-bottom', 100)};

  > h2 {
    color: #141414;

    ${vw('font-size', 18, 18, 28)};
    ${vw('margin-bottom', 14, 0)};
  }
`;

const RightCol = styled.div`
  width: 100%;

  @media ${media.tablet} {
    width: 45%;
    margin-left: 5%;
  }

  > p {
    color: #141414;
    margin-top: 0;

    ${vw('font-size', 12, 13, 16)}
    ${vw('letter-spacing', 1.6, 2)}
    ${vw('line-height', 18, 17.5, 21)}
    ${vw('margin-bottom', 40, 60)}

    > br {
      @media ${media.desktop} {
        display: none;
      }
    }
  }

  > a {
    text-transform: uppercase;
    background-color: rgb(168, 166, 161);
    border-radius: 3px;
    color: #141414;
    text-decoration: none;
    outline: none;
    border: none;
    transition: all 0.25s ease-in-out;

    ${vw('font-size', 12)}
    ${vw('letter-spacing', 1)}
    ${vw('padding-top', 10, 20)}
    ${vw('padding-bottom', 10, 20)}
    ${vw('padding-left', 12, 25)}
    ${vw('padding-right', 12, 25)}

    &:hover {
      background-color: rgb(181, 181, 182);
      color: #9e3882;
    }
  }
`;

export default Banner
