const hanasho = {
  id: 'hanasho',
  name: '花蕉',
  city: '臺北',
  space: '商空',
  size: '40坪',
  photographic: 'Ar Her Kuo Photography Studio',
  coop: '隱作設計',
  description: {
    left: (
      <>
        店門吊掛的金屬簾，依稀流瀉出的暖光，隱身在喧鬧臺北東區巷弄內的神祕日式料理。
        整體空間以「日式侘寂」(侘び寂び )為設計始點，並採用「四進式」設計：一進庭園、二進玄關、三進待亭、四進板前。透過四進式的動線，讓每個場域空間勾勒獨特的日式姿態，在享有美食前，先增添一場視覺的美好饗宴。
        甫踏入「庭園區」，中央以吊燈高懸，象徵一輪明月，月下礫石水紋形成枯山水 (かれさんすい；日本式寫意園林的一種純淨皎潔意象)，並與四周斜面的石紋牆，引向玄關靜謐復古氛圍。
        「玄關區」，以燒杉與實木拼貼為庭園點一滴墨，店門佐以木格柵設計，以線條的虛實交錯手法，增添寧靜而溫暖的視覺感。
      </>
    ),
    right: (
      <>
        入店後，「待亭區(等待區)」以木格柵為主，搭配實木皮及霧面茶色玻璃，特定選用圓型玻璃，彷彿將庭院的月光照映空間。牆面底部以洗牆燈照明，讓光線和緩的延伸至圓弧牆面及天花板，讓整體氛圍更為舒適飽滿。另外，該區的木格柵隱藏兩個拉門，以不同動線進入私人包廂或板前區，達到區隔空間並締造輕透的視覺隱蔽效果。
        「板前區」延續傳統日式建築懸山頂的意象，並融入純粹金屬材質，結合現代設計之演繹方式。為塑造光與溫潤的場域，簷下採用線形燈為主照明，板前區的用餐桌面選用日本杉木，並特意請木工師傅製作偏低一點的桌面高度，讓客人以更近距離欣賞料理食材及揉捏壽司的過程，一場視覺與味覺的雙重享受，細細體驗空間的沉靜之美。
        以日式侘寂之美學，一粒石、一盞燈、一輪明月、一懸山、一暖席、一壺酒，讓空間回歸到質樸的寧靜之美。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/hanasho/01.jpg'),
    require('assets/images/portfolio/hanasho/02.jpg'),
    require('assets/images/portfolio/hanasho/03.jpg'),
    require('assets/images/portfolio/hanasho/04.jpg'),
    require('assets/images/portfolio/hanasho/05.jpg'),
    require('assets/images/portfolio/hanasho/06.jpg'),
    require('assets/images/portfolio/hanasho/07.jpg'),
    require('assets/images/portfolio/hanasho/08.jpg'),
    require('assets/images/portfolio/hanasho/09.jpg'),
    require('assets/images/portfolio/hanasho/10.jpg'),
    require('assets/images/portfolio/hanasho/11.jpg'),
    require('assets/images/portfolio/hanasho/12.jpg'),
    require('assets/images/portfolio/hanasho/13.jpg'),
    require('assets/images/portfolio/hanasho/14.jpg'),
    require('assets/images/portfolio/hanasho/15.jpg'),
    require('assets/images/portfolio/hanasho/16.jpg'),
    require('assets/images/portfolio/hanasho/17.jpg'),
    require('assets/images/portfolio/hanasho/18.jpg'),
  ]
};

export default hanasho;