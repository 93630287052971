import React from 'react'
import styled from '@emotion/styled'

import Container from 'components/Container';

import vw from 'styles/vw';
import media from 'styles/media';

function Contact() {
  return (
    <Root as="main">
      <LeftColumn>
        <Title>
          <h2>Contact</h2>
          <p>
            如有設計的需求或想法，歡迎聯絡我們
            <HomeIcon src={require('assets/images/contact/home.png')} alt="home" />
          </p>
        </Title>
        <Info>
          <Tel>
            <h3>Tel</h3>
            <a href="tel:0932-767-129">0932-767-129</a>
          </Tel>
          <Line>
            <h3>Line</h3>
            <a href="http://lin.ee/GjbW4Up">
              <span>lin.ee/GjbW4Up</span>
              <span>(LINE ID: @348ydryg)</span>
            </a>
            <LineQR src={require('assets/images/contact/qr/L_gainfriends_qr.png')} alt="qr" />
          </Line>
          <Email>
            <h3>Email</h3>
            <a href="mailto:zhushidesign@gmail.com">zhushidesign@gmail.com</a>
          </Email>
          <Address>
            <h3>Address</h3>
            <a
              href="https://www.google.com/maps?q=%E8%87%BA%E5%8C%97%E5%B8%82%E5%A4%A7%E5%AE%89%E5%8D%80%E7%BE%85%E6%96%AF%E7%A6%8F%E8%B7%AF%E4%BA%8C%E6%AE%B579%E8%99%9F9%E6%A8%93%E4%B9%8B7&um=1&ie=UTF-8&sa=X&ved=2ahUKEwimyPedyNT4AhXsKkQIHbwuDXkQ_AUoAXoECAEQAw"
              target="_blank" rel="noreferrer"
            >
              臺北市大安區羅斯福路二段79號9樓之7
            </a>
          </Address>
        </Info>
      </LeftColumn>
      <RightColumn>
        <img src={require('assets/images/contact/contact.jpg')} alt="contact" />
      </RightColumn>
    </Root>
  )
}

Contact.propTypes = {}

const Root = styled(Container)`
  display: flex;
  flex-direction: column;
  ${vw('padding-bottom', 20, 30, 60)}

  @media ${media.tablet} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const LeftColumn = styled.div`
  width: 100%;
  position: relative;

  ${vw('margin-top', 0, 40, 70)}
  ${vw('margin-bottom', 30, 0)}

  @media ${media.tablet} {
    width: 45%;
    padding-left: 5%;
  }

  @media ${media.desktop} {
    width: 40%;
    padding-left: 10%;
  }
`;

const RightColumn = styled.div`
  width: 100%;
  text-align: center;
  ${vw('margin-bottom', 60, 0)}

  @media ${media.tablet} {
    width: 45%;
    padding-right: 5%;
  }

  @media ${media.desktop} {
    width: 40%;
    padding-right: 10%;
  }

  > img {
    width: 100%;
    height: auto;

    @media ${media.tablet} {
      width: 90%;
    }

    @media ${media.desktop} {
      width: 80%;
    }
  }
`;

const Title = styled.div`
  color: #141414;

  ${vw('margin-bottom', 22)}
  
  > h2 {
    color: #141414;

    ${vw('font-size', 16, 15, 18)}
    ${vw('margin-bottom', 30)}
  }

  > p {
    width: max-content;
    color: #141414;
    margin: 0;
    position: relative;

    ${vw('font-size', 13, 13, 15)}
  }
`;

const HomeIcon = styled.img`
  display: inline;
  height: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate3d(130%, 0, 0);

  ${vw('width', 30)}
  ${vw('paddign-left', 10)}
`;

const Info = styled.div`
  position: relative;

  h3 {
    color: #141414;

    ${vw('font-size', 13, 13, 15)}
    ${vw('width', 70, 75, 100)}
  }

  a {
    color: #141414;
    text-decoration: none;
    outline: none;
    border: none;

    ${vw('font-size', 11.5, 13, 15)}
    ${vw('margin-bottom', 12)}
  }
`;

const Tel = styled.div`
  display: flex;
  transition: all 0.25s ease-in-out;

  &:hover {
    a {
      color: #9e3882;
    }
  }
`;

const Email = styled.div`
  display: flex;
  transition: all 0.25s ease-in-out;

  &:hover {
    a {
      color: #9e3882;
    }
  }
`;

const Address = styled.div`
  display: flex;
  transition: all 0.25s ease-in-out;

  &:hover {
    a {
      color: #9e3882;
    }
  }
`;

const Line = styled.div`
  width: max-content;
  display: flex;
  transition: all 0.25s ease-in-out;
  position: relative;

  > a > span {
    display: block;

    ${vw('margin-bottom', 10)}
  }

  &:hover {
    a {
      color: #9e3882;
    }
  }
`;

const LineQR = styled.img`
  width: auto;
  height: auto;
  position: absolute;
  transform: translate3d(150%, 0, 0);

  ${vw('max-width', 60, 80, 100)}
  ${vw('max-height', 60, 80, 100)}
  ${vw('top', 22, -10)}
  ${vw('right', 0)}
`;

export default Contact
