import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import HamburgerMenu from 'react-hamburger-menu'

import vw from 'styles/vw';
import media from 'styles/media';

function NavMobile(props) {
	const { openHamburger, handelMenuClicked, handleClosedMenu } = props;

	return (
		<Root>
			<HamburgerMenu
				isOpen={openHamburger}
				menuClicked={handelMenuClicked}
				width={30}
				height={20}
				strokeWidth={2}
				rotate={0}
				color={openHamburger ? 'white' : '#9e3882'}
				borderRadius={0}
				animationDuration={0.5}
				className="hamburger"
			/>
			<Modal show={openHamburger}>
				<Nav>
					<Link to="/about" onClick={handleClosedMenu}>About</Link>
					<Link to="/portfolio" onClick={handleClosedMenu}>Portfolio</Link>
					<Link to="/service-process" onClick={handleClosedMenu}>Service Process</Link>
					<Link to="/contact" onClick={handleClosedMenu}>Contact</Link>
				</Nav>
			</Modal>
		</Root>
	)
}

NavMobile.propTypes = {
	openHamburger: PropTypes.bool, 
	handelMenuClicked: PropTypes.func, 
	handleClosedMenu: PropTypes.func
}

const Root = styled.nav`
	${vw('margin-bottom', 10)}

	@media ${media.tablet} {
    display: none;
  }

	.hamburger {
		z-index: 20;
	}
`;

const Modal = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: #9e3882;
	z-index: 11;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.25s ease-in-out;
	opacity: ${props => props.show ? '1' : '0'};
	pointer-events: ${props => props.show ? 'auto' : 'none'};
`;

const Nav = styled.nav`
	display: flex;
	flex-direction: column;

	> a {
		color: white;
		text-align: center;
		text-decoration: none;
		text-transform: uppercase;

		${vw('font-size', 16)}

		&:not(:last-of-type) {	
			${vw('margin-bottom', 20)}
		}
	}
`;

export default NavMobile
