import constructionIcon from 'assets/images/icons/construction.svg';

const constructionStage = {
  title: '工程階段',
  icon: constructionIcon,
  stages: [
    {
      title: '工程合約簽訂',
      listItems: [
        '-工程進度執行',
        '-工地管理紀錄',
        '-工程品質監督',
        '-回報工地進度及現場狀況',
        '-傢俱及軟件裝飾建議'
      ]
    },
    {
      title: '完工交屋',
      listItems: [
        '-驗收交屋',
        '-完工攝影',
        '-售後服務',
        '-永久諮詢'
      ]
    }
  ]
};

export default constructionStage;