const yinShanRuShi = {
  id: 'yin-shan-ru-shi',
  name: '引山入室',
  city: '臺北',
  space: '住宅',
  size: '70坪',
  description: {
    left: (
      <>
        鑒於本案環繞山岳與河畔的自然風景，團隊特意採用大面開窗的設計，為住宅空間迎來和煦日光與壯麗景觀，帶給居者愜意放鬆的氛圍。此案運用基本的線形構圖擘劃場域，以簡潔俐落的線條，訴說空間結構之美。透過流暢線性的比例建構空間，並以自然質樸的大地元素回歸基調，同時，結合基地的天然絕佳採光優勢，演繹出悠然靜寂、暖煦敞亮的居宅風貌，構築最純粹自在的生活。 <br />
        從玄關甫入公領域，以暖灰、米白及木質色為主調，揉合灑入室內的自然日光，為空間烘托柔和淡雅的層次感。經由團隊的巧妙構思，打破原先格局的限制，重新解構畸零空間，將客廳、餐廳、廚房及茶室等區域整合為一體，並結合櫃體配置、牆面包覆及線條切割等設計，讓空間保有收納機能的彈性，亦勾勒出開闊亮敞的格局動線。為營造更為寬廣的空間尺度，團隊獨具匠心地選用古銅鍍鈦板包覆橫貫空間的大樑，既成為銜接場域的介質，亦作為區域的界定，更藉由材料的金屬質地延伸公領域的大器磅薄感，從而消弭原先橫樑產生的壓迫感，達到延續空間的視覺通透效果。
      </>
    ),
    right: (
      <>
        推開隱藏牆面的暗門，臥室空間採用沉穩暖灰色礦物塗料鏝抹於牆面，搭配溫潤木質的櫃體與地版，塑造恬靜舒適的氛圍，挹注安定的愜意氣息，讓居宅找回最原始的寧靜感。臥室以純淨溫煦的暖調，天花板綴以融合山稜的圓弧曲線，堆疊出空間獨特的脈絡和寧靜之美。<br />
        本案以基本的「線、面、體」為主軸，呈現空間原有樣貌，圍塑乾淨俐落的視覺感受。空間中摻揉素淨淡雅的色彩、樸質的自然肌理，營造純淨的空間韻味，透過適度留白的基調，讓濃淡光影得以恣意交織，日光沐浴交錯映現於空間裡，為家描繪出恬靜溫暖的畫面。<br />
        此外，設計團隊亦汲取環山起伏的蜿蜒曲線，如山稜般的弧線線條設計，轉譯成客廳電視櫃及臥室天花板的靈感，與窗外環山的自然景緻互為呼應，以山形象徵的線條從戶外綿延不絕引入室內，柔和整體空間的線形輪廓，在空間中享受山的生活，釀出靜謐的自然氛圍。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/yin-shan-ru-shi/01.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/02.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/03.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/04.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/05.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/06.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/07.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/08.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/09.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/10.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/11.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/12.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/13.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/14.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/15.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/16.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/17.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/18.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/19.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/20.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/21.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/22.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/23.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/24.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/25.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/26.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/27.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/28.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/29.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/30.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/31.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/32.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/33.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/34.jpg'),
    require('assets/images/portfolio/yin-shan-ru-shi/35.jpg'),
  ]
};

export default yinShanRuShi;