import React from 'react'
import styled from '@emotion/styled'

import Container from './Container';

import vw from 'styles/vw';
import media from 'styles/media';

function Footer() {
  return (
    <Root as="footer">
      <Row>
        <LeftCol>
          <a href="/" target="_blank" rel="noreferrer">
            <h3>Zhu Shi design studio</h3>
          </a>
        </LeftCol>
        <IconsWrapper>
          <a href="https://www.facebook.com/zhushidesign/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className='fa-brands fa-facebook' />
          </a>
          <a href="https://www.instagram.com/zhushidesign/" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className='fa-brands fa-instagram-square' />
          </a>
          <a href="https://lin.ee/GjbW4Up" target="_blank" rel="noreferrer">
            <FontAwesomeIcon className='fa-brands fa-line' />
          </a>
        </IconsWrapper>
        <RightCol>
          <a href="tel: +886-932-767-129" target="_blank" rel="noreferrer">0932-767-129</a>
          <a href="mailto: zhushidesign@gmail.com" target="_blank" rel="noreferrer">zhushidesign@gmail.com</a>
          <a
            href="https://www.google.com/maps?q=%E8%87%BA%E5%8C%97%E5%B8%82%E5%A4%A7%E5%AE%89%E5%8D%80%E7%BE%85%E6%96%AF%E7%A6%8F%E8%B7%AF%E4%BA%8C%E6%AE%B579%E8%99%9F9%E6%A8%93%E4%B9%8B7&um=1&ie=UTF-8&sa=X&ved=2ahUKEwimyPedyNT4AhXsKkQIHbwuDXkQ_AUoAXoECAEQAw"
            target="_blank" rel="noreferrer"
          >
            臺北市大安區羅斯福路二段79號9樓之7
          </a>
        </RightCol>
      </Row>
    </Root>
  )
}

const Root = styled(Container)`
  background: rgb(181, 181, 182);

  ${vw('padding-top', 30)}
  ${vw('padding-bottom', 30)}
`;

const Row = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  @media ${media.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const LeftCol = styled.div`
  width: 100%;

  @media ${media.tablet} {
    width: 35%;
  }

  ${vw('margin-bottom', 14, 0)}

  > a {
    color: #141414;
    text-decoration: none;
    outline: none;
    border: none;
    transition: all 0.25s ease-in-out;

    ${vw('font-size', 14, 13, 15)}

    &:hover {
      color: #9e3882;
    }

    > h3 {
      ${vw('font-size', 12, 15, 18)}
    }
  }
`;


const IconsWrapper = styled.div`
  width: 100%;
  display: flex;

  @media ${media.tablet} {
    justify-content: center;
    align-items: center;
    width: 30%;
  }

  ${vw('margin-bottom', 5, 0)}
  ${vw('gap', 21.5)}
`;

const FontAwesomeIcon = styled.i`
  color: #141414;
  transition: all 0.25s ease-in-out;

  ${vw('font-size', 30, 30, 40)}

  &:hover {
    color: #9e3882;
  }
`;

const RightCol = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media ${media.tablet} {
    width: 35%;
    align-items: flex-end;
  }

  ${vw('margin-top', 10, 0)}

  > a {
    color: #141414;
    text-decoration: none;
    outline: none;
    border: none;
    transition: all 0.25s ease-in-out;

    ${vw('font-size', 10, 12)}
    ${vw('letter-spacing', 1)}
    ${vw('line-height', 20, 18, 24)}

    &:hover {
      color: #9e3882;
    }
  }
`;

export default Footer
