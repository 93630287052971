const pandaTea885 = {
  id: "panda-tea-885",
  name: "PANDA TEA - 885",
  city: "加拿大",
  space: "商空",
  size: "22坪",
  description: {
    left: (
      <>
        以加拿大最具代表性的自然山景，北美洲西部的洛磯山脈作為主要設計概念，整體空間以「群山意象」之斜面屋頂為主軸，彷彿穿越峻山深谷，置身於一家蘊含著藝術與茶藝的珍珠奶茶飲品據點。
        <br />
        此案設計理念以「與自然共鳴」注入整體空間，配合建築物本身的斜頂構造，特以木皮板為斜面屋頂設計為設計發想，象徵著一層層連綿不絕的山勢，每個座位正像是一築寧靜的露營駐地，讓顧客在此專屬空間中尋找屬於自己的片刻寧靜，宛如享受天然的茶香芬多精。
      </>
    ),
    right: (
      <>
        空間材質選用水磨石磁磚、木皮板、金屬美耐板及礦物塗料，以融合自然元素為出發始點，透過現代語彙的設計手法，讓材質與環境揉合為一體，為整體空間增添畫龍點睛的效果。
        <br />
        首先，水磨石磁磚由地板延伸至櫃檯，流動性連貫從平面至立面，同時避免過多的繁複裝飾，選擇簡潔的點線面切割櫃體；接著，象徵山脈層次的木皮天花板，展現東方侘寂美學，營造樸實生活的禪意之中，彷彿置身於山水間，彰顯著幽靜清新的茶香氛圍；再來，展示櫃藉由金屬美耐板為材質，讓陽光透過板材特性折射，創造穿梭流動性的光線，堆疊交織的自然光影烘托下，為室內帶來不同時間軌跡的細緻光線變化，呼應山林間依循著日出日落的自然生息。
        <br />
        本案空間以禪風為定調，讓此處不僅為一家珍珠奶茶飲品店，更是一處寧靜舒適的品茶據點，在有限的空間條件之下，透過設計手法創造溫潤香氛的渲染氛圍，讓清新東方茶香在一隅商業空間中增添知性氣息，如同茶香般一縷縷地清淡而悠遠，造出沉靜休憩心靈的質感所在。
      </>
    ),
  },
  gallery: [
    require("assets/images/portfolio/panda-tea-885/01.jpg"),
    require("assets/images/portfolio/panda-tea-885/02.jpg"),
    require("assets/images/portfolio/panda-tea-885/03.jpg"),
    require("assets/images/portfolio/panda-tea-885/04.jpg"),
    require("assets/images/portfolio/panda-tea-885/05.jpg"),
    require("assets/images/portfolio/panda-tea-885/06.jpg"),
    require("assets/images/portfolio/panda-tea-885/07.jpg"),
    require("assets/images/portfolio/panda-tea-885/08.jpg"),
    require("assets/images/portfolio/panda-tea-885/09.jpg"),
    require("assets/images/portfolio/panda-tea-885/10.jpg"),
    require("assets/images/portfolio/panda-tea-885/11.jpg"),
    require("assets/images/portfolio/panda-tea-885/12.jpg"),
    require("assets/images/portfolio/panda-tea-885/13.jpg"),
    require("assets/images/portfolio/panda-tea-885/14.jpg"),
    require("assets/images/portfolio/panda-tea-885/15.jpg"),
    require("assets/images/portfolio/panda-tea-885/16.jpg"),
  ],
};

export default pandaTea885;
