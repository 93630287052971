const yubai = {
  id: 'yubai',
  name: '餘白',
  city: '臺北',
  space: '住宅',
  size: '30坪',
  photographic: '崴米鍶空間攝影 Weimax Studio',
  coop: '隱作設計',
  description: {
    left: (
      <>
        內湖市郊，業主一家三代同堂，兼顧不同世代的生活需求，是本案空間規劃的一大重點。
        此住宅為三面採光，具有光線充足的先天優勢。將舊有的裝修拆除後，原動線有兩支大樑的問題截然而生，考量業主預算範圍，在廚房與廁所均不變動的前提下，將格局配置再略作調整，以減緩大樑的壓迫感。
        首先，客廳空間依循著大樑，利用側邊包覆的側版空間隱藏管線，並將佈線引至後陽台；接著，房間隔間依循著小樑，先刻意地將床位閃避樑，降低睡眠品質受影響之可能性，再巧妙地運用設計手法，將無樑處的天花板高度予以提升，以保有挑高寬敞的舒適感。最後，利用「弧型」修飾，削弱大樑及畸零隔間，讓原本突兀的大樑，化身為空間的一個視覺焦點，替天花板勾勒豐富的層次。
      </>
    ),
    right: (
      <>
        客餐廳是三代共處聊天的核心區域，透過開放的動線規劃，使交流空間更加寬敞。而客餐廳與廚房的交界，以具穿透感的金屬框玻璃作區隔，一方面讓採光可以溜進空間，達到視覺上的通透，另一方面也運用此開放式廚房的設計，讓家人互動更加零距離。透過設計手法將客廳、餐廳及廚房不同機能的三個空間整合，達到寬敞、明亮且動線流暢的起居空間，勾勒舒適溫暖的氛圍。
        以木地板延伸串起家中幸福的氣息，選用暖色系的橡木色調，讓空間富有寧靜且沉穩感。考量業主收藏許多色彩濃郁的傢具，於是特意以低彩度的奶茶色噴漆為廚房櫃體，再以不同深淺的灰色系大理石作為餐桌及廚房檯面，展現簡約大器的質感；電視牆面採用淺白色的礦物漆，特意讓空間留一點白，當陽光灑落，光影流動讓空間形成最自然恬靜的面貌。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/yubai/1.jpg'),
    require('assets/images/portfolio/yubai/2.jpg'),
    require('assets/images/portfolio/yubai/3.jpg'),
    require('assets/images/portfolio/yubai/4.jpg'),
    require('assets/images/portfolio/yubai/5.jpg'),
    require('assets/images/portfolio/yubai/6.jpg'),
    require('assets/images/portfolio/yubai/7.jpg'),
    require('assets/images/portfolio/yubai/8.jpg'),
    require('assets/images/portfolio/yubai/9.jpg'),
    require('assets/images/portfolio/yubai/10.jpg'),
    require('assets/images/portfolio/yubai/11.jpg'),
    require('assets/images/portfolio/yubai/12.jpg'),
    require('assets/images/portfolio/yubai/13.jpg'),
    require('assets/images/portfolio/yubai/14.jpg'),
    require('assets/images/portfolio/yubai/15.jpg'),
    require('assets/images/portfolio/yubai/16.jpg'),
    require('assets/images/portfolio/yubai/17.jpg'),
    require('assets/images/portfolio/yubai/18.jpg'),
    require('assets/images/portfolio/yubai/19.jpg'),
    require('assets/images/portfolio/yubai/20.jpg'),
  ]
}

export default yubai;