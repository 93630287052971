import React from 'react'
import styled from '@emotion/styled'

import Container from 'components/Container';

import vw from 'styles/vw';
import media from 'styles/media';

function About() {
  return (
    <Root as="main">
        <LeftCol backgroundImage={require('assets/images/about/about.jpeg')} />
        <RightCol>
          <ul>
            <li>
              <h2>- Concept -</h2>
              <ConceptHead>
                <span>「諸」&#9670; 言者，所有說話的使用者</span>
                <span>「室」&#9670; 空間，生活的介面</span>
              </ConceptHead>
              <ConceptBody>
                <p>
                  <span>諸室設計</span>
                  <span>從溝通切入，用心生活</span>
                  <span>空間的形成，透過你與我互相溝通及參與，</span>
                  <span>使用者與設計師共同打造空間的美學生活，</span>
                  <span>一同回歸最單純的美好。</span>
                </p>
                <p>
                  <span>讓諸室設計營造一個專屬於你的空間，</span>
                  <SpanWithMoibleBR>規劃最符合使用者習慣的動線及格局配置，<br />聚焦實用功能，</SpanWithMoibleBR>
                  <span>演繹無造作的舒適空間。</span>
                </p>
              </ConceptBody>
              <ConceptFoot>諸事平安，諸室大吉</ConceptFoot>
            </li>
            <Certificate>
              <h2>- Certificate -</h2>
              <ul>
                <li>內政部建築物室內裝修專業技術人員<span>(專業設計技術人員、專業施工技術人員)</span></li>
                <li>內政部建築物室內裝修業登記證<span>(字號：內營室業字第40E2013854號)</span></li>
              </ul>
            </Certificate>
            <Service>
              <h2>- Service -</h2>
              <ul>
                <li>住宅空間設計</li>
                <li>商業空間設計</li>
                <li>辦公空間設計</li>
              </ul>
            </Service>
          </ul>
        </RightCol>
    </Root>
  )
}

About.propTypes = {}

const Root = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  @media ${media.desktop} {
    flex-direction: row;
  }
  
  ${vw('margin-top', 30, 50, 50)}
  ${vw('padding-bottom', 60)}
`;

const LeftCol = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => `url(${props.backgroundImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  ${vw('min-height', 400, 470)}
  ${vw('margin-bottom', 40, 50, 0)}

  @media ${media.desktop} {
    width: 50%;
  }
`;

const RightCol = styled.div`
  width: 100%;
  /* margin-top: 100px; */

  @media ${media.desktop} {
    width: 50%;
  }

  /* ${vw('margin-top', 0, 0, 40)} */
  ${vw('padding-left', 0, 200, 0)}

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    color: #141414;

    ${vw('font-size', 13, 13, 15)}
    ${vw('margin-bottom', 0, 30, 0)}
    ${vw('margin-left', 0, 0, 80)}
  }

  li {
    ${vw('margin-bottom', 6)}
  }

  h2 {
    color: #141414;
    text-transform: uppercase;

    ${vw('font-size', 16, 15, 18)}
    ${vw('margin-bottom', 15, 15, 12)}
  }

  p {
    margin: 0;
  }
`;

const ConceptHead = styled.div`
  ${vw('font-size', 13, 13, 15)}
  ${vw('margin-bottom', 30, 18, 20)}

  > span {
    display: block;

    ${vw('margin-bottom', 6)}
  }
`;

const ConceptBody = styled.div`
  ${vw('margin-bottom', 18, 0)}
  
   > p {
    ${vw('margin-bottom', 18, 18, 20)}

    > span {
      display: block;

      ${vw('font-size', 13, 13, 15)}
      ${vw('margin-bottom', 6)}
    }
  }
`;

const SpanWithMoibleBR = styled.span`
  display: block;

  ${vw('font-size', 13, 13, 15)}
  ${vw('line-height', 20)}
  ${vw('margin-bottom', 6)}

  > br {
    @media ${media.tablet} {
      display: none;
    }
  }
`;

const ConceptFoot = styled.div`
  ${vw('font-size', 13, 13, 15)}
  ${vw('margin-bottom', 45, 50, 50)}
`;

const Certificate = styled.div`
  ${vw('margin-bottom', 45, 50, 50)}

  ul {
    margin-left: 0;
  }

  li {
    ${vw('line-height', 20)}

    ${vw('margin-bottom', 18, 18, 20)}

    > span {
      display: block;

      ${vw('font-size', 13, 13, 15)}
    }
  }
`;

const Service = styled.div`

  ul {
    margin-left: 0;
  }

  li {
    ${vw('line-height', 20)}
  }
`;

export default About
