import Home from 'pages/Home/Home';
import About from 'pages/About/About';
import ServiceProcess from 'pages/ServiceProcess/ServiceProcess';
import Portfolio from 'pages/Portfolio/Portfolio';
import ProjectDetail from 'pages/ProjectDetail/ProjectDetail';
import Contact from 'pages/Contact/Contact';

const routes = [
  {
    path: "/",
    element: <Home />
  },
  {
    path: "/about",
    element: <About />
  },
  {
    path: "/service-process",
    element: <ServiceProcess />
  },
  {
    path: "/portfolio",
    element: <Portfolio />
  },
  {
    path: "/portfolio/:id",
    element: <ProjectDetail />
  },
  {
    path: "/contact",
    element: <Contact />
  },
]

export default routes;