import React from 'react'
import styled from '@emotion/styled'

import Gallery from './sections/Gallery';
import Banner from './sections/Banner';

function Home() {
  return (
    <Root>
      <Gallery />
      <Banner />
    </Root>
  )
}

const Root = styled.main``;

export default Home
