const songyeTech = {
  id: "songye-tech",
  name: "白與墨｜崧燁科技辦公室",
  city: "臺北",
  space: "辦公室",
  size: "45坪",
  description: {
    left: (
      <>
        本案運用大量的「留白與比例」作為設計概念，鋪陳出樸素俐落的細膩質感，透過純淨潔白並點綴沉穩墨色的色彩，渲染溫潤安定的辦公氛圍；空間規劃則使用「弧線線條」為主軸，為空間捎來輕盈的曲面調性， 軟化科技產業的制式感，構築圓融的工作環境。
        <br />
        甫踏入空間，「玄關區」以一道半掩的圓弧牆面，由小至大的開口，特定作為一處過境空間，讓員工從戶外進入辦公室的情境轉換，創造流暢的動線，更乘載著職場團隊的夢想與向心力。
        <br />
        「辦公區」以半高片狀屏風作為座位區隔，跳脫傳統屏風的方式，提供光線穿梭的途徑，將自然光線與人造光線互相交織，使光影層次有更多變化性，此設計達到辦公隔間的隱密性，並兼顧敞亮、通透的視覺效果，烘托出開闊大器、靜謐和諧的辦公質感。
      </>
    ),
    right: (
      <>
        「小會議區」以半高隱私牆面為設計，並在牆面邊緣特定使用凹溝設計，宛如精心雕刻的溝縫，強化曲線的立體度，勾勒半高牆面的輕薄質感，再以手作塗抹的藝術漆搭配，圍塑出簡約乾淨的視覺風格；「大會議區」則採用圓弧的天花板造型，增添整體的柔和溫潤感，並化繁為簡將櫃體內嵌於牆體，滿足收納機能之餘，亦讓自然採光充分灑落空間，創造舒適的辦公環境。
        <br />
        「董事長室」以書房的概念為主軸，圓弧曲線的天花板，降低銳利感及制式感，使整體空間更加柔和，除此之外，以墨色木紋質材揉入辦公桌與櫃體，透過自然木紋的肌理層次，注入董事長一室的沉穩內斂質感。
        <br />
        本案科技業辦公空間特定以「適度留白」的設計手法，融入墨色木質元素妝點，簡單雋永地醞釀洗鍊俐落的辦公氛圍，挹注安定調性的意境，創造更富饒的深度及專業度。
      </>
    ),
  },
  gallery: [
    require("assets/images/portfolio/songye-tech/01.jpg"),
    require("assets/images/portfolio/songye-tech/02.jpg"),
    require("assets/images/portfolio/songye-tech/03.jpg"),
    require("assets/images/portfolio/songye-tech/04.jpg"),
    require("assets/images/portfolio/songye-tech/05.jpg"),
    require("assets/images/portfolio/songye-tech/06.jpg"),
    require("assets/images/portfolio/songye-tech/07.jpg"),
    require("assets/images/portfolio/songye-tech/08.jpg"),
    require("assets/images/portfolio/songye-tech/09.jpg"),
    require("assets/images/portfolio/songye-tech/10.jpg"),
    require("assets/images/portfolio/songye-tech/11.jpg"),
    require("assets/images/portfolio/songye-tech/12.jpg"),
    require("assets/images/portfolio/songye-tech/13.jpg"),
    require("assets/images/portfolio/songye-tech/14.jpg"),
    require("assets/images/portfolio/songye-tech/15.jpg"),
    require("assets/images/portfolio/songye-tech/16.jpg"),
    require("assets/images/portfolio/songye-tech/17.jpg"),
    require("assets/images/portfolio/songye-tech/18.jpg"),
    require("assets/images/portfolio/songye-tech/19.jpg"),
    require("assets/images/portfolio/songye-tech/20.jpg"),
    require("assets/images/portfolio/songye-tech/21.jpg"),
    require("assets/images/portfolio/songye-tech/22.jpg"),
    require("assets/images/portfolio/songye-tech/23.jpg"),
    require("assets/images/portfolio/songye-tech/24.jpg"),
    require("assets/images/portfolio/songye-tech/25.jpg"),
    require("assets/images/portfolio/songye-tech/26.jpg"),
    require("assets/images/portfolio/songye-tech/27.jpg"),
  ],
};

export default songyeTech;
