const plantea = {
  id: 'plantea',
  name: 'PLANTEA 植茶 ｜ 植物奶茶飲專賣店',
  city: '臺北',
  space: '商空',
  size: '30坪',
  photographic: '小雄梁彥空間影像製作',
  furniture: '光悅制作',
  coop: '隱作設計',
  description: {
    left: (
      <>
        綠意盎然的三角窗，南京復興站巷內。
        業主希望保有屋內原始痕跡，因此在選用材料上，我們以原始材料為主軸，而非加工材質，以貼近「自然」的重要理念。
        「自然純粹」為此案的設計初始點，整體空間不做多餘的裝修，只做必要的修飾。首先，我們保留自然刷痕的水泥地坪，接著，材料運用上，特別挑選具原始紋理的材質，如夾板染色、回收木棧板、自然紋理的清水漆料等，讓空間回歸純粹，與業主的飲食理念(天然植物奶)互相呼應。
      </>
    ),
    right: (
      <>
        空間中的動線規劃，捨棄傳統飲料店的模式，秉持簡單直覺的概念，以「L型中島」區隔顧客區與工作區，其餘空間則適度的留白，讓「顧客」和「製作職人」成為空間的主角，在垂吊植物與愛迪生燈泡之錯落間，享受都市中寧靜的一角。
        牆面方面，運用大量的黑板漆(Chalkboard paint；可用粉筆書寫如同傳統黑板一樣)，店家可以隨手塗鴉，以筆觸分享溫暖的訊息，亦可與插畫家或藝術家合作，手繪創作圖像，讓作品呈現於牆面，用黑板漆傳遞質樸的書寫空間，打造兼具機能及美學特色的功能。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/plantea/1.jpg'),
    require('assets/images/portfolio/plantea/2.jpg'),
    require('assets/images/portfolio/plantea/3.jpg'),
    require('assets/images/portfolio/plantea/4.jpg'),
    require('assets/images/portfolio/plantea/5.jpg'),
    require('assets/images/portfolio/plantea/6.jpg'),
    require('assets/images/portfolio/plantea/7.jpg'),
    require('assets/images/portfolio/plantea/8.jpg'),
    require('assets/images/portfolio/plantea/9.jpg'),
    require('assets/images/portfolio/plantea/10.jpg'),
    require('assets/images/portfolio/plantea/11.jpg'),
  ]
}

export default plantea;
