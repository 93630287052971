const highTopMedical = {
  id: 'high-top-medical',
  name: 'HIGH TOP MEDICAL 高頂｜辦公室',
  city: '臺北',
  space: '辦公室',
  size: '52坪',
  photographic: '墨田工作室',
  coop: '隱作設計',
  description: {
    left: (
      <>
        現代風。光與圓拱
        此案設計以「光」的概念作為核心，將「光燈」置於辦公室中心，運用投射的光線，讓室內有光影的變化，無邊際的照耀空間，恰與業主信仰相互呼應。
        整體空間以「圓拱設計」為設計主軸，天花板及壁龕（牆面設計的凹槽，用以裝飾壁面或展示商品）採用弧型曲線，光燈外觀亦呈現橢圓狀，使空間更為圓潤，有別於傳統辦公室。
        此圓拱設計是以流暢線條，弱化空間稜角的尖銳感，創造視覺與空間的平衡性。更重要的是，在功能方面，以弧形曲線連接每個空間，讓室內的機能使用更富有彈性，創造美學與機能兼備的場域。
      </>
    ),
    right: (
      <>
        建築師高第曾言「直線屬於人類，曲線屬於上帝」（Antoni Gaudí, 1852-1926）。辦公空間以光燈引領，輔以圓弧曲線，光與線條互為搭配，讓空間相對柔和。
        此外，色調選用沉靜灰為主色調，佐以不同深淺的灰色堆疊而成，透過光燈的照射，使灰階在明暗度有所變化，締造簡約又富有層次的視覺效果。
        以光為始，乘載著職場的夢想與希望。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/high-top-medical/1.jpg'),
    require('assets/images/portfolio/high-top-medical/2.jpg'),
    require('assets/images/portfolio/high-top-medical/3.jpg'),
    require('assets/images/portfolio/high-top-medical/4.jpg'),
    require('assets/images/portfolio/high-top-medical/5.jpg'),
    require('assets/images/portfolio/high-top-medical/6.jpg'),
    require('assets/images/portfolio/high-top-medical/7.jpg'),
    require('assets/images/portfolio/high-top-medical/8.jpg'),
    require('assets/images/portfolio/high-top-medical/9.jpg'),
    require('assets/images/portfolio/high-top-medical/10.jpg'),
    require('assets/images/portfolio/high-top-medical/11.jpg'),
    require('assets/images/portfolio/high-top-medical/12.jpg'),
    require('assets/images/portfolio/high-top-medical/13.jpg'),
    require('assets/images/portfolio/high-top-medical/14.jpg'),
    require('assets/images/portfolio/high-top-medical/15.jpg'),
    require('assets/images/portfolio/high-top-medical/16.jpg'),
    require('assets/images/portfolio/high-top-medical/17.jpg'),
    require('assets/images/portfolio/high-top-medical/18.jpg'),
    require('assets/images/portfolio/high-top-medical/19.jpg'),
    require('assets/images/portfolio/high-top-medical/20.jpg'),
  ]
};

export default highTopMedical;
