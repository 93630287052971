import React from 'react'
import styled from '@emotion/styled'

import Container from 'components/Container';
import CustomizedTimeline from './elements/CustomizedTimeline';

import vw from 'styles/vw';
import media from 'styles/media';

function ServiceProcess() {
  return (
    <Root>
      <CustomizedTimeline />
    </Root>
  )
}

ServiceProcess.propTypes = {}

const Root = styled(Container)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  
  @media ${media.desktop} {
    flex-direction: row;
  }
  
  ${vw('margin-top', 30, 50, 50)}
  ${vw('padding-bottom', 60)}
`;

export default ServiceProcess
