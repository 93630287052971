const riri = {
  id: 'riri',
  name: '日日',
  city: '臺北',
  space: '辦公室',
  size: '15坪',
  description: {
    left: (
      <>
        工作空間應該是甚麼型態呢？
        有別於一般工作空間的直硬、制式，日常工作樣貌應該更加輕巧優雅，靜謐中又帶入一縷活潑的生氣。
        選用結構組接式傢俱、自然木頭紋理材質、天然建材、花朵點綴裝飾空間，其餘的就留給白與光。
        以移動式傢俱作為空間搭配，例如半高層板櫃體併列圍塑出玄關區域，隨心情與季節陳列書籍、擺飾品及植栽，讓訪客從喧擾繁雜的街道之中，一踏入玄關就可以感受到安穩心靜的氛圍。
      </>
    ),
    right: (
      <>
        討論區以樺木桌板及木質桌腳為主，三角造型桌腳賦有隱藏版收納平台，可以舒服自在地放置隨身物品，再點亮一盞吊燈就讓彼此對話更有溫度。
        工作區域則以松木組合櫃為主，分類擺放各種蒐羅到的建材樣品，偶時信手拈來的圖稿圖紙也隨性放置此區，成為每個日常熬夜趕圖在背後默默安穩支持的力量。
        保有空間自由，簡化裝修堆疊，進入工作空間，最純粹的簡約彷彿回到心寧的安居場所。
        心放鬆，溫度就暖了。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/riri/1.jpg'),
    require('assets/images/portfolio/riri/2.jpg'),
    require('assets/images/portfolio/riri/3.jpg'),
    require('assets/images/portfolio/riri/4.jpg'),
    require('assets/images/portfolio/riri/5.jpg'),
    require('assets/images/portfolio/riri/6.jpg'),
    require('assets/images/portfolio/riri/7.jpg'),
    require('assets/images/portfolio/riri/8.jpg'),
    require('assets/images/portfolio/riri/9.jpg'),
    require('assets/images/portfolio/riri/10.jpg'),
    require('assets/images/portfolio/riri/11.jpg'),
    require('assets/images/portfolio/riri/12.jpg'),
  ]
};

export default riri;