import { useState } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import { Box, Container, Grid, List, ListItem, ListItemText } from '@mui/material'; // Grid version 1

import timelineData from 'data/timeline';
import homeIcon from 'assets/images/icons/home.svg';

export default function CustomizedTimeline() {
  const [activeList, setActiveList] = useState(null);

  const handleOnMouseEnter = (index) => {
    setActiveList(index)
  }

  const hanldeOnMouseLeave = () => {
    setActiveList(null)
  }

  return (
    <Container maxWidth="sm">
      <Timeline sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}>
        {
          timelineData.map((timeline, indexX) => (
            <>
              <TimelineItem sx={{mt: 2}}>
                <TimelineSeparator>
                  <Box
                    component="img"
                    src={timeline.icon}
                    sx={{ width: '32px' }}
                  />
                  <TimelineConnector sx={{py: 6, my: 2}}/>
                </TimelineSeparator>
                <Grid container>
                  <TimelineContent sx={{fontWeight: "bold", color: "#9e3882"}}>{timeline.title}</TimelineContent>
                </Grid>
              </TimelineItem>
              {
                timeline?.stages.map((stage, indexY) => (
                  <TimelineItem>
                    <TimelineSeparator onMouseEnter={() => handleOnMouseEnter(`${indexX}${indexY}`)} onMouseLeave={hanldeOnMouseLeave} sx={{ minWidth: '32px' }}>
                      <TimelineDot outlinedGrey color={activeList === `${indexX}${indexY}` ? "secondary" : "grey"} sx={{ transition: 'all 0.25s ease-in-out', m: '10px auto' }} />
                      <TimelineConnector sx={{py: 10, bgcolor: activeList === `${indexX}${indexY}` && "#9e3882", transition: 'all 0.25s ease-in-out'}}/>
                    </TimelineSeparator>
                    <Grid container spacing={{sm: 6}} mb={{xs: 2, sm: 0}} onMouseEnter={() => handleOnMouseEnter(`${indexX}${indexY}`)} onMouseLeave={hanldeOnMouseLeave} >
                      <Grid item xs={12} sm={5}>
                        <TimelineContent sx={{color: activeList === `${indexX}${indexY}` && "#9e3882", fontWeight: "bold", transition: 'all 0.25s ease-in-out'}}>{stage.title}</TimelineContent>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <List sx={{ mt: '-4px' }}>
                          {
                            stage?.listItems.map((listItem) => (
                              <ListItem disablePadding >
                                <ListItemText primary={listItem} primaryTypographyProps={{ color: activeList === `${indexX}${indexY}` && "#9e3882" }} />
                              </ListItem>
                            ))
                          }
                        </List>
                      </Grid>
                    </Grid>
                  </TimelineItem>
                ))
              }

            </>
          ))
        }
        <TimelineItem>
          <TimelineSeparator>
            <Box
              component="img"
              src={homeIcon}
              sx={{ width: '32px', my: 2 }}
            />
            {/* <TimelineConnector /> */}
          </TimelineSeparator>
        </TimelineItem>
      </Timeline>
    </Container>
  );
}
