const songGuang = {
  id: 'song-guang',
  name: '頌光',
  city: '新北',
  space: '住宅',
  size: '23坪',
  description: {
    left: (
      <>
        蔥綠山林宅，用日光頌揚空間裡的吉光片羽。<br />
        屋主為一對小夫妻（其實也是先前案子「小居」的屋主），因為工作關係需要work from home，因此「創造不會互相干擾的倆人工作空間」是本案主軸之一。<br />
        甫入玄關區，為提升玄關收納櫃體的多元性，除保有一般收納功能之外，更將其中一道櫃體轉換為全開書桌櫃，運用木板材質的門片開闔，隱藏書桌於櫃體內，整合機能與空間的功能，並讓空間呈現寬敞通透的樣貌。當太陽升起，親手泡一杯醇厚的卡布其諾，順手推開門片，開啟工作行程；當夕陽落下，收拾桌上的工作雜物，輕巧地闔上門片，讓職場的煩囂暫時遠離生活，家又回到安頓心靈的溫馨居所。<br />
        本案考量原先建商配置的書房區過大，導致公共區的動線有所侷限，於是我們將書房牆面退縮，放大延伸餐廳區域，讓客廳動線更為流暢，亦達到寬敞開闊的視覺效果。 接著，運用全展開的玻璃拉門重新定義書房區，以「複合式機能的書房設計」為考量，關閉拉門時，仍兼具獨立隱私的遮蔽性；敞開拉門時，則與餐廳區互為連接，讓溫煦的日光更為流動與通透，譜出居家寧靜的氣息。
      </>
    ),
    right: (
      <>
        餐廳區特定配置於住宅之中心，以一張台灣檜木餐桌為主角，搭配鄉村風裝飾的餐椅，並利用線版元素之暖白色餐櫃作為點綴，於玻璃拉門的光影調和之下，呈現微甜的鄉村溫馨感，讓空間揉入暖度與朝氣的氛圍。<br />
        於是，一個公共區域，倆人工作空間，保有各自獨立隱私的機能性，亦兼具空間的彈性及整體完整性，營造出動線寬敞、採光充足的舒適住所。<br />
        又為敞開空間的垂直高度，彙整客餐廳的主要管線，沿著橫樑延伸至後陽台，化為一條整齊的收納動線，並將橫樑處作基本包覆，於包覆的邊緣部分做出溝縫設計，弱化樑線的量體感，讓樑成為空間中輕巧的存在，勾勒出簡潔俐落的場域輪廓。<br />
        而本案最值得一提的巧思設計是，考量臥室前方有一隅天花板的畸零空間，我們運用鄉村風元素特定製為裝飾樑，錯落排列成十字架形體，漆成自然感的木質色調，並將燈具藏於裝飾樑之後，當點亮光線照耀，呈現十字架光影的灑落，在住宅中心頌光，彷彿輕聲地訴說愛的細語。<br />
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/song-guang/01.jpg'),
    require('assets/images/portfolio/song-guang/02.jpg'),
    require('assets/images/portfolio/song-guang/03.jpg'),
    require('assets/images/portfolio/song-guang/04.jpg'),
    require('assets/images/portfolio/song-guang/05.jpg'),
    require('assets/images/portfolio/song-guang/06.jpg'),
    require('assets/images/portfolio/song-guang/08.jpg'),
    require('assets/images/portfolio/song-guang/09.jpg'),
    require('assets/images/portfolio/song-guang/10.jpg'),
    require('assets/images/portfolio/song-guang/11.jpg'),
    require('assets/images/portfolio/song-guang/07.jpg'),
    require('assets/images/portfolio/song-guang/13.jpg'),
    require('assets/images/portfolio/song-guang/14.jpg'),
    require('assets/images/portfolio/song-guang/12.jpg'),
    require('assets/images/portfolio/song-guang/15.jpg'),
    require('assets/images/portfolio/song-guang/16.jpg'),
    require('assets/images/portfolio/song-guang/17.jpg'),
    require('assets/images/portfolio/song-guang/18.jpg'),
    require('assets/images/portfolio/song-guang/19.jpg'),
    require('assets/images/portfolio/song-guang/20.jpg'),
    require('assets/images/portfolio/song-guang/21.jpg'),
    require('assets/images/portfolio/song-guang/22.jpg'),
    require('assets/images/portfolio/song-guang/23.jpg'),
    require('assets/images/portfolio/song-guang/24.jpg'),
    require('assets/images/portfolio/song-guang/25.jpg'),
    require('assets/images/portfolio/song-guang/26.jpg'),
  ]
};

export default songGuang;