const upDownResidence = {
  id: 'up-down-residence',
  name: '上下行宅',
  city: '新北',
  space: '住宅',
  size: '25坪',
  photographic: '墨田工作室',
  cloth: '艾立思軟裝集團',
  coop: '隱作設計',
  description: {
    left: (
      <>
        擁有4.2米挑高及迎面大窗戶採光的天生優勢，我們透過設計手法，去除原先阻隔自然光的格局，將一個家核心機能，整合至樓梯踏階及中島吧檯，讓自然光灑落空間。
        「衛浴洗手槽結合中島吧檯」是設計的主要核心，此設計手法不同於一般住宅形式，偏向飯店式型態。一般來說，衛浴洗手槽只用於洗漱，現在將其與廚房的中島吧檯合而為一，除保有基本的盥洗功能之外，亦可作為聚餐餐檯、收納電器櫃等多功能用途，業主可以彈性運用該區域，發揮空間最大的價值。
      </>
    ),
    right: (
      <>
        選用材質方面，考量業主想要展現男子性格的空間氛圍，因此挑選以沉穩的木頭色系為主，再輔以不同濃郁的灰色色系，如中島吧檯採用灰色的大理石、玄關地坪以清水模磁磚並延伸至走道、電視牆利用手作質感的樂土灰泥。接著，窗戶採用木百頁簾的格柵設計，以陰翳建構光影的明暗美學，帶來豐富層次的色調。
        另外，舒適生活中，衛浴空間也是不可或缺的角色。原有的衛浴空間，離窗戶最遠也較陰暗狹小，為了讓陽光可以灑落浴室，馬桶區選用半透光的玻璃磚，淋浴推門使用髮絲紋的半透玻璃，開燈時，光線會穿透半透材質，鋪灑客廳區域，宛如曬一場溫暖的日光浴，幸福就是這麼簡單。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/up-down-residence/1.jpg'),
    require('assets/images/portfolio/up-down-residence/2.jpg'),
    require('assets/images/portfolio/up-down-residence/3.jpg'),
    require('assets/images/portfolio/up-down-residence/4.jpg'),
    require('assets/images/portfolio/up-down-residence/5.jpg'),
    require('assets/images/portfolio/up-down-residence/6.jpg'),
    require('assets/images/portfolio/up-down-residence/7.jpg'),
    require('assets/images/portfolio/up-down-residence/8.jpg'),
    require('assets/images/portfolio/up-down-residence/9.jpg'),
    require('assets/images/portfolio/up-down-residence/10.jpg'),
    require('assets/images/portfolio/up-down-residence/11.jpg'),
    require('assets/images/portfolio/up-down-residence/12.jpg'),
    require('assets/images/portfolio/up-down-residence/13.jpg')
  ]
};

export default upDownResidence;
