const f100 = {
  id: "f100",
  name: "F100號常設展",
  city: "臺北",
  space: "住宅",
  size: "18坪",
  description: {
    left: (
      <>
        「如果你看見的是藍色，不管蘋果或兔子都可以畫成藍色。」（Pablo
        Picasso畢卡索）
        <br />
        「以畫溝通」為設計主軸，本案別出心裁將屋主的渡假宅，打破一般住宅的制式感，以「藝術油畫尺寸F100號」作為客廳電視牆比例，勾勒出一幅藝術畫作，營造居宅的藝術空間。
        <br />
        步入空間，運用玄關櫃體的引導，電視牆抹去以往的傳統的框架，以淺灰石紋薄磚為材質，以油畫尺寸為配置，結合美感構面的比例，宛如「一幅畫作」，將整體空間轉化為藝術畫廊，營造生動的場域氣息，讓屋主愜意享受片刻的寧靜，並感受層次堆疊的藝術魅力。此外，客廳空間軟件選用藤編長凳，坐在長凳彷彿與藝術電視牆進行一場對話，搭配陽光斜灑的明暗變化，交織而成的光影濃淡，更增添空間層次感的藝術氛圍。
      </>
    ),
    right: (
      <>
        開放廚房的中島，巧妙地與電視櫃設計為同一水平高度，降低空間的視覺重量感，化繁為簡讓空間線條乾淨，形成簡約俐落的設計；順沿著牆面，排列著收納的高櫃，以矩形層層堆疊設計，將空間劃分得更具層次感，形成整齊畫一的格局，提升空間的收納機能，並營造功能與美感兼具的居宅空間。
        <br />
        為區隔客餐廳與書房的公私領域，以拉門作為調節空間的優勢，強化客餐廳與書房之間的連結性。當開啟拉門時，串連兩領域，延伸空間的視野，並讓光線自在穿梭以保持通透性；當閉合拉門時，分隔兩領域，保有書房的隱密性。臥室的衣帽間也以拉門區隔，用一抹色調調和寧靜感，並在靠窗處結合臥榻機能，為享受度假的屋主，打造休憩生活的一隅。
        <br />
        以「畫」為設計理念，運用電視牆的比例長度，溫潤空間的溫度，讓屋主在度假的居宅空間，享受不同於日常的忙碌生活，從中感受一縷縷的平靜氛圍，捕捉一絲藝術的氣息，極簡而不失溫暖，彷彿置身於一場永恆F100號常設展。
      </>
    ),
  },
  gallery: [
    require("assets/images/portfolio/f100/01.jpg"),
    require("assets/images/portfolio/f100/02.jpg"),
    require("assets/images/portfolio/f100/03.jpg"),
    require("assets/images/portfolio/f100/04.jpg"),
    require("assets/images/portfolio/f100/05.jpg"),
    require("assets/images/portfolio/f100/06.jpg"),
    require("assets/images/portfolio/f100/07.jpg"),
    require("assets/images/portfolio/f100/08.jpg"),
    require("assets/images/portfolio/f100/09.jpg"),
    require("assets/images/portfolio/f100/10.jpg"),
    require("assets/images/portfolio/f100/11.jpg"),
    require("assets/images/portfolio/f100/12.jpg"),
    require("assets/images/portfolio/f100/13.jpg"),
    require("assets/images/portfolio/f100/14.jpg"),
    require("assets/images/portfolio/f100/15.jpg"),
    require("assets/images/portfolio/f100/16.jpg"),
    require("assets/images/portfolio/f100/17.jpg"),
    require("assets/images/portfolio/f100/18.jpg"),
    require("assets/images/portfolio/f100/19.jpg"),
    require("assets/images/portfolio/f100/20.jpg"),
    require("assets/images/portfolio/f100/21.jpg"),
    require("assets/images/portfolio/f100/22.jpg"),
  ],
};

export default f100;
