const yangyang = {
  id: 'yangyang',
  name: '秧秧',
  city: '臺北',
  space: '住宅',
  size: '25坪',
  description: {
    left: (
      <>
        北市巷弄四口之家，其樂融融。
        業主期望「簡單舒適」入住。因為是老屋，所以丈量時有特別留意屋況，一踏入屋內時發現濕氣偏重，磁磚也多處嚴重攏起，一切的簡單似乎不簡單。另外，房屋位於小巷弄，所以有採光不足的難題，也是這次設計要解決的一筆挑戰。
        屋內分為一樓（1F）及地下室（B1）兩區塊。地下室（B1）部分，著重在基礎工程及漏水處理，施工過程可以說是驚心動魄（從小水滴逐漸變成小溪流、湧泉，源源不絕流出），由於地下室的漏水大多是從牆上湧出，為避免後續再度漏水，所以在牆角處以截水溝的方式處理，將牆面及地板內的漏水引流至蓄水池，再抽到下水道排放。
        一樓（1F）部分，原先格局僅有餐廳但沒有廚房，與屋主的生活需求並不相符，所以我們決定以不壓縮居住空間的條件下，將格局重新調配，打造更友善使用者的舒適居家宅。順應空間的既有條件，我們將原先餐廳位置重新配置為廚房，巧妙地讓「廚房」位於家的中心，並將廚房轉角設計為一道透明弧形隔間（有別於一般直角），並利用玻璃拉門增加採光的亮度，達到延伸空間的感覺。
      </>
    ),
    right: (
      <>
        除軟化一進門看見的牆面外，也賦予互相串連的功能，在住宅的中心建築一處能陪伴孩子的溫馨料理環境。起居室則捨棄常見的電視牆形式，改以輕巧的旋轉電視，增加便利性，也保留基本的電視牆機能，搭配電視吊櫃與支撐腳，讓原本厚重的電視牆呈現另一種輕盈的姿態。
        更重要的是，親子宅規劃以「安全性」為首要考量（屋主兩位孩子較年幼），盡量減少不必要的直角，利用弧形語彙詮釋空間，例如櫃體轉角、天花板、廚房的弧形玻璃、甚至廚房拉門把手也都延續此元素，讓孩子們擁有歡樂無拘的成長空間。小孩房方面，以書桌及收納櫃體簡單呈現，減少大量裝修，讓空間保有靈活性，孩子未來長大後也可以更加彈性運用。
        整體材質及色調選擇以木色與大地色為主，輔以橄欖綠、中性灰馬來漆為開放區域的主色，利用植萃色調營造出舒適自在的休憩空間，讓孩子如「秧秧」（植物的幼苗）般，在簡潔幸福的家裡快樂成長茁壯。
      </>
    )
  },
  gallery: [
    require('assets/images/portfolio/yangyang/01.jpg'),
    require('assets/images/portfolio/yangyang/02.jpg'),
    require('assets/images/portfolio/yangyang/03.jpg'),
    require('assets/images/portfolio/yangyang/04.jpg'),
    require('assets/images/portfolio/yangyang/05.jpg'),
    require('assets/images/portfolio/yangyang/06.jpg'),
    require('assets/images/portfolio/yangyang/07.jpg'),
    require('assets/images/portfolio/yangyang/08.jpg'),
    require('assets/images/portfolio/yangyang/09.jpg'),
    require('assets/images/portfolio/yangyang/10.jpg'),
    require('assets/images/portfolio/yangyang/11.jpg'),
    require('assets/images/portfolio/yangyang/12.jpg'),
    require('assets/images/portfolio/yangyang/13.jpg'),
    require('assets/images/portfolio/yangyang/14.jpg'),
    require('assets/images/portfolio/yangyang/15.jpg'),
    require('assets/images/portfolio/yangyang/16.jpg'),
    require('assets/images/portfolio/yangyang/17.jpg'),
    require('assets/images/portfolio/yangyang/18.jpg'),
    require('assets/images/portfolio/yangyang/19.jpg'),
    require('assets/images/portfolio/yangyang/20.jpg'),
  ]
};

export default yangyang;