import React from 'react'
import Slider from "react-slick";
import styled from '@emotion/styled'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import slickImages from 'data/home/home';

import vw, { vwDesktop, vwTablet } from 'styles/vw';
import appConfig from 'config/app.conf';

const settings = {
  dots: false,
  infinite: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
};

function Gallery() {
  return (
    <Root>
      <Slider {...settings}>
        {
          slickImages?.map((image) => (
            <Image src={image.src} key={`home ${image.src}`} alt="image" bgPosition={image.position}/>
          ))
        }
      </Slider>
    </Root>
  )
}

const Root = styled.div`
  width: 100%;
  
  ${vw('margin-bottom', 80)};
`;

const Image = styled.div`
  width: 100%;
  background: ${props => `url(${props.src})`};
  background-size: cover;
  background-position: ${props=> `${props.bgPosition} center`};

  ${vw('height', 350, 400, 550)};

  @media only screen and (max-width: ${appConfig.stage.tablet}px) and (min-width: ${appConfig.mediaQuery.tablet}px) {
    height: ${vwTablet(670)};
  }

  @media only screen and (max-width: 1176px) and (min-width: ${appConfig.mediaQuery.desktop}px) {
    height: ${vwDesktop(735)};
  }
`;

export default Gallery
